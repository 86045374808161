export default {
  'device.router.name': 'Device',
  'device.router.nameBreadcrumb': 'Device Management',
  'device.title': 'Managing Devices',
  'device.table.name': 'Device Name',
  'device.table.code': 'Identification Code',
  'device.table.specificAddress': 'Installation Address',
  'device.table.operationStatus': 'Operation Status',
  'device.table.connectionStatus': 'Connection Status',
  'device.table.playingStatus': 'Broadcast Status',
  'device.table.operationStatus.param':
    '{status,select, 1 {Active} 2 {Inactive} other {Undefined}}',
  'device.table.connectionStatus.param':
    '{status,select, 1 {Connected} 2 {Disconnected} other {Undefined}}',
  'device.table.playingStatus.param':
    '{status,select, 1 {Broadcasting} 2 {Stopped} other {Undefined}}',
  'device.delete.title': 'Device Deletion Confirmation',
  'device.delete.content':
    'Are you sure to delete the selected device(s), this action can not be reversed?',
  'device.restart.title': 'Device Restart Confirmation',
  'device.restart.content': 'The selected device(s) will be restarted',
  'device.volume.title': 'Device Volume Control',
  'device.filter.operationStatus': 'Operation Status',
  'device.filter.operationStatus.active': 'Active',
  'device.filter.operationStatus.notActive': 'Inactive',
  'device.filter.connectionStatus': 'Connection Status',
  'device.filter.connectionStatus.active': 'Connected',
  'device.filter.connectionStatus.notActive': 'Disconnected',
  'device.filter.playingStatus': 'Broadcast Status',
  'device.filter.playingStatus.active': 'Broadcasting',
  'device.filter.playingStatus.notActive': 'Stopped',
  'device.qrCode.title': 'Device Login Code',
  'device.qrCode.text': 'Scan QR code or enter code to log in to the device',
  'device.modal.create': 'Add Device',
  'device.modal.update': 'Update Device',
  'device.modal.create.form.name': 'Device Name',
  'device.modal.create.form.name.validator': 'Invalid device name format',
  'device.modal.create.form.code': 'Device Identification Code',
  'device.modal.create.form.code.validator': 'Invalid identification code format',
  'device.modal.create.form.subscriberNumber': 'SIM Number',
  'device.modal.create.form.serialNumber': 'SIM Serial Number',
  'device.modal.create.form.supplierId': 'Provider',
  'device.modal.create.form.operationStatus': 'Operation Status',
  'device.modal.create.form.operationStatus.active': 'Active',
  'device.modal.create.form.operationStatus.notActive': 'Inactive',
  'device.modal.create.form.description': 'Description',
  'device.router.info.name': 'Device Information',
  'device.info.tabs.detail': 'Device Information',
  'device.info.tabs.broadcast': 'Broadcast History',
  'device.info.tabs.activity': 'Device Activity History',
  'device.info.detail.sidebar.title': 'Device Information',
  'device.info.detail.sidebar.name': 'Device Name',
  'device.info.detail.sidebar.code': 'Device Identification Code',
  'device.info.detail.sidebar.specificAddress': 'Address',
  'device.info.detail.sidebar.subscriberNumber': 'SIM Number',
  'device.info.detail.sidebar.serialNumber': 'SIM Serial Number',
  'device.info.detail.sidebar.supplierId': 'Provider',
  'device.info.detail.sidebar.sdFree': 'Device Capacity',
  'device.info.detail.sidebar.version': 'Version',
  'device.info.detail.sidebar.operationStatus': 'Status',
  'device.info.detail.sidebar.operationStatus.param':
    '{status,select, 1 {Active} 2 {Inactive} other {Undefined}}',
  'device.info.detail.sidebar.connectionStatus': 'Connection Status',
  'device.info.detail.sidebar.connectionStatus.param':
    '{status,select, 1 {Connected} 2 {Disconnected} other {Undefined}}',
  'device.info.detail.sidebar.playingStatus': 'Broadcast Status',
  'device.info.detail.sidebar.playingStatus.param':
    '{status,select, 1 {Broadcasting} 2 {Stopped} other {Undefined}}',
  'device.info.detail.sidebar.description': 'Description',
  'device.info.detail.sidebar.location': 'Location',
  'device.info.detail.delete.title': 'Device Deletion Confirmation',
  'device.info.detail.delete.content':
    'Are you sure to delete the selected device(s), this action can not be reversed?',
  'device.info.detail.restart.title': 'Device Restart Confirmation',
  'device.info.detail.restart.content': 'The selected device(s) will be restarted',
  'device.info.detail.card.normal': 'Normal Schedule',
  'device.info.detail.card.prioritize': 'Priority Schedule',
  'device.info.detail.card.bulletinStatus.id':
    '{status, select, 1 {Waiting for Approval} 2 {Refused} 3 {Canceled} 4 {Unprocessed} 5 {Broadcasting} 6 {Paused} 7 {Finished} 8 {Waiting for Broadcast} 9 {Upcoming} other {--}}',
  'device.info.detail.calendar.day': 'Day',
  'device.info.detail.calendar.title': 'Broadcast Schedule',
  'device.info.detail.calendar.viewBy': 'View By',
  'device.info.detail.calendar.titleDate': '1. All Day Schedule',
  'device.info.detail.calendar.myNew': 'My Schedule(s)',
  'device.info.detail.calendar.fatherNew': 'Appointed Schedule(s)',
  'device.info.detail.calendar.cancelNew': 'Cancelled Schedule(s)',
  'device.info.detail.calendar.viewMore': 'View More',
  'device.info.detail.calendar.newOther': 'Other Schedule(s)',
  'device.info.detail.calendar.viewLess': 'View Less',
  'device.info.detail.calendar.hour': 'Hour',
  'device.info.detail.calendar.minutes': 'Minutes',
  'device.info.detail.calendar.titleTime': '2. By Hours Schedule',
  'device.info.detail.calendar.moreTime': 'Time Mode',
  'device.info.playLogs.title': 'Broadcast History',
  'device.info.playLogs.table.newsletter.title': 'Schedule Name',
  'device.info.playLogs.table.duration': 'Duration',
  'device.info.playLogs.table.startTime': 'Start Time',
  'device.info.playLogs.table.endTime': 'End Time',
  'device.info.playLogs.filter.createdAt': 'Start Time',
  'device.info.activityHistory.title': 'Device Activity History',
  'device.info.activityHistory.table.time': 'Time',
  'device.info.activityHistory.table.actionType': 'Action',
  'device.info.activityHistory.table.note': 'Content',
  'device.info.activityHistory.filter.createdAt': 'Time',
  'device.info.activityHistory.table.actionType.downloadSuccessfully': 'Downloaded Successfully',
  'device.info.activityHistory.table.actionType.downloadError': 'Download Failed',
  'device.info.activityHistory.table.actionType.broadcast': 'Broadcast',
  'device.info.activityHistory.table.actionType.paused': 'Paused Broadcast',
  'device.info.activityHistory.table.actionType.requestServer': 'Deleted Broadcast',
  'device.info.activityHistory.table.actionType.expired': 'Deleted Expired Broadcast',
  'device.info.activityHistory.table.actionType.connected': 'Reconnected',
  'device.info.activityHistory.table.actionType.disconnected': 'Disconnected',
  'device.info.activityHistory.table.actionType.reboot': 'Restarted Device',
  'device.info.activityHistory.table.actionType.adjustVolume': 'Adjusted Volume',
  'device.info.activityHistory.table.actionType.newsLetterEnded': 'Broadcast Ended',
  'device.info.activityHistory.table.actionType.newsLetterResume': 'Resumed Broadcast',
  'device.info.activityHistory.table.actionType.changeInfoSource': 'Change information source',
  'device.modalInfoLogin.title': 'Confirmation of login',
  'device.modalCode.title': 'Enter the device code',
  'device.modalCode.text': 'Enter the login code displayed on the device screen',
  'device.modalCode.form.code': 'Equipment code',
  'common.login-qr': 'Log in device',
  'device.quantity.label': 'Number of devices',
  'device.quantity.warning.title': 'You have reached the device limit',
  'device.quantity.warning.content':
    'The number of devices has reached the limit of the current subscription you are using. You can change to another subscription or remove inactive devices.',
  'device.quantity.warning.btn.upgrade': 'Upgrade',
  'device.quantity.warning.message':
    'You do not have permission to access this feature, please contact the organization owner.',
  'device.modalInfoLogin.text': 'If you log in, the connected device will automatically log out.',
  'device.login-device': 'Log in',
  'device.refuse': 'Refuse',
  'device.time': 'Time',
  'device.address': 'Location',
  'device.login-qr': 'Log in device',
  'device.inform.title': 'Notification',
  'device.inform.content':
    'You are approaching the device limit of the upcoming plan. According to the plan, by {date}, the new plan will be limited to {deviceQuantity} device(s). You may consider adjusting the plan for a better fit.',
  'device.inform.okText': 'Subscription Management',

  // update device group
  'device.table.deviceGroup': 'Device group',
  'device.modal.create.form.deviceGroup': 'Device group',
  'device.info.detail.sidebar.deviceGroup': 'Device group',
  'device.filter.deviceGroups': 'Device group',
};
