import React from 'react';
import { Command } from 'react-feather';

import { IRouter } from '@routers/interface';

export const routerDeviceDashboardOrganizations: IRouter = {
  path: '/dashboard/organizations/:organizationId/device-dashboard',
  // path: '/dashboard/device-dashboard',
  loader: React.lazy(() => import('./index')),
  activePath: '/dashboard',
  exact: true,
  name: 'Dashboard', //translate here for breadcrumb and sidebar
  masterLayout: true,
  nameBreadcrumb: 'dashboard.router.manager',
  // generatePath(organizationId) {
  //   return `/organizations/${organizationId}/device-dashboard`;
  // },
  menu: {
    icon: <Command />,
    hideInNavbar: true,
  },
};

export const routerDeviceDashboard: IRouter = {
  path: '/dashboard/device-dashboard',
  // path: '/dashboard/device-dashboard',
  loader: React.lazy(() => import('./index')),
  activePath: '/dashboard',
  exact: true,
  name: 'Dashboard', //translate here for breadcrumb and sidebar
  masterLayout: true,
  nameBreadcrumb: 'dashboard.router.manager',
  // generatePath(organizationId) {
  //   return `/organizations/${organizationId}/device-dashboard`;
  // },
  menu: {
    icon: <Command />,
    hideInNavbar: true,
  },
};
