export default {
  'facility.router.name': 'Cơ sở',
  'facility.router.nameBreadcrumb': 'Quản lý cơ sở',
  'facility.tabs.area': 'Thông tin cơ sở',
  'facility.tabs.user': 'Quản lý người dùng',
  'facility.tabs.device': 'Thiết bị',
  'facility.tabs.media': 'Media',
  'facility.tabs.template': 'Bản mẫu',
  'facility.tabs.playlist': 'Danh sách phát',
  'facility.tree.delete.title': 'Xóa cơ sở?',
  'facility.tree.delete.content':
    'Bạn có chắc xóa cơ sở {name}. Cơ sở bị xóa sẽ không thể không phục',
  'facility.tree.dropdown.add': 'Tạo cơ sở trực thuộc',
  'facility.tree.dropdown.edit': 'Cập nhật thông tin cơ sở',
  'facility.tree.dropdown.delete': 'Xóa cơ sở',
  'facility.info.title': 'Thông tin cơ sở',
  'facility.info.code': 'Mã cơ sở',
  'facility.info.name': 'Tên cơ sở',
  'facility.info.parentId': 'Trực thuộc',
  'facility.info.operationStatus': 'Trạng thái hoạt động',
  'facility.info.operationStatus.param':
    '{status,select, 1 {Đang hoạt động} 2 {Ngưng hoạt động} other {Khác}}',
  'facility.modal.create': 'Thêm cơ sở trực thuộc',
  'facility.modal.update': 'Cập nhật thông tin cơ sở',
  'facility.modal.form.code': 'Mã cơ sở',
  'facility.modal.form.name': 'Tên cơ sở',
  'facility.modal.form.parentId': 'Trực thuộc',
  'facility.modal.form.operationStatus': 'Trạng thái hoạt động',
  'facility.router.user.name': 'Quản lý người dùng',
  'facility.user.title': 'Tài khoản quản trị cơ sở',
  'facility.user.table.username': 'Tên đăng nhập',
  'facility.user.table.name': 'Họ và tên',
  'facility.user.table.phoneNumber': 'Số điện thoại',
  'facility.user.table.email': 'Email',
  'facility.user.table.role.name': 'Vai trò',
  'facility.user.table.status': 'Trạng thái',
  'facility.user.table.status.params':
    '{status,select, 1 {Đang hoạt động} 2 {Ngưng hoạt động} other {Không xác định}}',
  'facility.user.table.lastAccessedAt': 'Truy cập lần cuối',
  'facility.user.delete.title': 'Xác nhận xóa tài khoản',
  'facility.user.delete.content':
    'Các tài khoản quản trị cơ sở được chọn sẽ bị xóa và không thể khôi phục.',
  'facility.user.filter.role': 'Vai trò',
  'facility.user.filter.status': 'Trạng thái',
  'facility.user.filter.status.active': 'Đang hoạt động',
  'facility.user.filter.status.notActive': 'Ngưng hoạt động',
  'facility.user.modal.information': 'Thông tin người dùng',
  'facility.user.modal.create': 'Tạo người dùng',
  'facility.user.modal.update': 'Cập nhật người dùng',
  'facility.user.modal.form.username': 'Tên đăng nhập',
  'facility.user.modal.form.username.validatorUserName': 'Tên đăng nhập không đúng định dạng',
  'facility.user.modal.form.username.placeholder': 'Vui lòng nhập tên đăng nhập',
  'facility.user.modal.form.username.note': 'Tên đăng nhập để truy cập là',
  'facility.user.modal.form.name': 'Họ và tên',
  'facility.user.modal.form.name.validatorUndefined': 'Họ và tên không đúng định dạng',
  'facility.user.modal.form.email': 'Email',
  'facility.user.modal.form.timezoneId': 'Múi giờ',
  'facility.user.modal.form.timezoneId.validator': 'Vui lòng nhập múi giờ',
  'facility.user.modal.form.password': 'Mật khẩu',
  'facility.user.modal.form.password.required':
    'Mật khẩu phải có ít nhất 8 ký tự, bao gồm chữ thường, chữ hoa, số và ký tự đặc biệt.',
  'facility.user.modal.form.phoneNumber': 'Số điện thoại',
  'facility.user.modal.form.identifierNumber': 'Số CMND/CCCD',
  'facility.user.modal.form.roleId': 'Vai trò',
  'facility.user.modal.form.roleId.validator': 'Vui lòng nhập vai trò',
  'facility.user.modal.form.status': 'Trạng thái hoạt động',
  'facility.user.modal.form.status.validator': 'Vui lòng nhập trạng thái hoạt động',
  'facility.user.modal.form.status.active': 'Đang hoạt động',
  'facility.user.modal.form.status.notActive': 'Ngưng hoạt động',
  'facility.user.modal.form.confirmPassWord': 'Xác nhận mật khẩu',
  'facility.user.modal.form.confirmPassWord.required': 'Hai mật khẩu bạn đã nhập không khớp',
  'facility.user.modal.form.userAvatar': 'Hình đại diện',
  'facility.router.device.name': 'Thông tin thiết bị cơ sở',
  'facility.device.title': 'Danh sách thiết bị cơ sở',
  'facility.device.table.name': 'Tên thiết bị',
  'facility.device.table.code': 'Mã định danh',
  'facility.device.table.specificAddress': 'Địa chỉ lắp đặt',
  'facility.device.table.facility.name': 'Cơ sở',
  'facility.device.table.operationStatus': 'Trạng thái hoạt động',
  'facility.device.table.connectionStatus': 'Trạng thái kết nối',
  'facility.device.table.playingStatus': 'Trạng thái phát bản tin',
  'facility.device.table.operationStatus.param':
    '{status,select, 1 {Đang hoạt động} 2 {Ngưng hoạt động} other {Không xác định}}',
  'facility.device.table.connectionStatus.param':
    '{status,select, 1 {Đang kết nối} 2 {Mất kết nối} other {Không xác định}}',
  'facility.device.table.playingStatus.param':
    '{status,select, 1 {Đang phát bản tin} 2 {Dừng phát bản tin} other {Không xác định}}',
  'facility.device.delete.title': 'Xác nhận xóa thiết bị',
  'facility.device.delete.content':
    'Các thiết bị được chọn sẽ bị xóa khỏi hệ thống và không thể khôi phục.',
  'facility.device.restart.title': 'Xác nhận khởi động lại thiết bị',
  'facility.device.restart.content': 'Các thiết bị được chọn sẽ được khởi động lại.',
  'facility.device.qrCode.title': 'Mã đăng nhập thiết bị',
  'facility.device.qrCode.text': 'Quét mã QR hoặc nhập code để đăng nhập thiết bị',
  'facility.device.volume.title': 'Điều chỉnh âm lượng của thiết bị được chọn',
  'facility.device.filter.operationStatus': 'Trạng thái hoạt động',
  'facility.device.filter.operationStatus.active': 'Đang hoạt động',
  'facility.device.filter.operationStatus.notActive': 'Ngưng hoạt động',
  'facility.device.filter.connectionStatus': 'Trạng thái kết nối',
  'facility.device.filter.connectionStatus.active': 'Đang kết nối',
  'facility.device.filter.connectionStatus.notActive': 'Mất kết nối',
  'facility.device.filter.playingStatus': 'Trạng thái phát bản tin',
  'facility.device.filter.playingStatus.active': 'Đang phát bản tin',
  'facility.device.filter.playingStatus.notActive': 'Dừng phát bản tin',
  'facility.device.modal.create': 'Thêm thiết bị',
  'facility.device.modal.update': 'Cập nhật thiết bị',
  'facility.device.modal.form.name': 'Tên thiết bị',
  'facility.device.modal.form.name.validator': 'Tên thiết bị không đúng định dạng',
  'facility.device.modal.form.code': 'Mã định danh thiết bị',
  'facility.device.modal.form.code.validator': 'Mã định danh thiết bị không đúng định dạng',
  'facility.device.modal.form.subscriberNumber': 'Số thuê bao SIM',
  'facility.device.modal.form.serialNumber': 'Số seri SIM',
  'facility.device.modal.form.supplierId': 'Nhà cung cấp',
  'facility.device.modal.form.operationStatus': 'Trạng thái hoạt động',
  'facility.device.modal.form.operationStatus.active': 'Đang hoạt động',
  'facility.device.modal.form.operationStatus.notActive': 'Ngưng hoạt động',
  'facility.device.modal.form.description': 'Mô tả',
  'facility.router.device.info.name': 'Thông tin thiết bị',
  'facility.device.info.tabs.detail': 'Thông tin thiết bị',
  'facility.device.info.tabs.broadcast': 'Lịch sử phát bản tin',
  'facility.device.info.tabs.activity': 'Lịch sử hoạt động của thiết bị',
  'facility.device.info.detail.sidebar.title': 'Thông tin thiết bị',
  'facility.device.info.detail.sidebar.name': 'Tên thiết bị',
  'facility.device.info.detail.sidebar.code': 'Mã định danh thiết bị',
  'facility.device.info.detail.sidebar.specificAddress': 'Địa chỉ',
  'facility.device.info.detail.sidebar.subscriberNumber': 'Số thuê bao SIM',
  'facility.device.info.detail.sidebar.serialNumber': 'Số seri SIM',
  'facility.device.info.detail.sidebar.supplierId': 'Nhà cung cấp',
  'facility.device.info.detail.sidebar.operationStatus': 'Trạng thái hoạt động',
  'facility.device.info.detail.sidebar.operationStatus.param':
    '{status,select, 1 {Đang hoạt động} 2 {Ngưng hoạt động} other {Không xác định}}',
  'facility.device.info.detail.sidebar.connectionStatus': 'Trạng thái kết nối',
  'facility.device.info.detail.sidebar.connectionStatus.param':
    '{status,select, 1 {Đang kết nối} 2 {Mất kết nối} other {Không xác định}}',
  'facility.device.info.detail.sidebar.playingStatus': 'Trạng thái phát',
  'facility.device.info.detail.sidebar.playingStatus.param':
    '{status,select, 1 {Đang phát bản tin} 2 {Dừng phát bản tin} other {Không xác định}}',
  'facility.device.info.detail.sidebar.description': 'Mô tả',
  'facility.device.info.detail.sidebar.location': 'Vị trí',
  'facility.device.info.detail.delete.title': 'Xác nhận xóa thiết bị',
  'facility.device.info.detail.delete.content':
    'Các thiết bị được chọn sẽ bị xóa khỏi hệ thống và không thể khôi phục.',
  'facility.device.info.detail.restart.title': 'Xác nhận khởi động lại thiết bị',
  'facility.device.info.detail.restart.content': 'Các thiết bị được chọn sẽ được khởi động lại.',
  'facility.device.info.playLogs.title': 'Lịch sử phát bản tin trên thiết bị',
  'facility.device.info.playLogs.table.newsletter.title': 'Tên bản tin',
  'facility.device.info.playLogs.table.duration': 'Thời lượng',
  'facility.device.info.playLogs.table.startTime': 'Thời gian bắt đầu phát',
  'facility.device.info.playLogs.table.endTime': 'Thời gian kết thúc phát',
  'facility.device.info.playLogs.filter.createdAt': 'Thời gian bắt đầu',
  'facility.device.info.activityHistory.title': 'Lịch sử hoạt động của thiết bị',
  'facility.device.info.activityHistory.table.time': 'Thời gian ',
  'facility.device.info.activityHistory.table.actionType': 'Hoạt động',
  'facility.device.info.activityHistory.table.note': 'Nội dung',
  'facility.device.info.activityHistory.filter.createdAt': 'Thời gian bắt đầu',
  'facility.device.info.activityHistory.table.actionType.downloadSuccessfully':
    'Tải bản tin thành công',
  'facility.device.info.activityHistory.table.actionType.downloadError': 'Tải bản tin thất bại',
  'facility.device.info.activityHistory.table.actionType.broadcast': 'Phát bản tin',
  'facility.device.info.activityHistory.table.actionType.paused': 'Tạm dừng bản tin',
  'facility.device.info.activityHistory.table.actionType.requestServer': 'Xóa bản tin',
  'facility.device.info.activityHistory.table.actionType.expired': 'Xóa bản tin hết hạn',
  'facility.device.info.activityHistory.table.actionType.connected': 'Kết nối lại',
  'facility.device.info.activityHistory.table.actionType.disconnected': 'Mất kết nối',
  'facility.device.info.activityHistory.table.actionType.reboot': 'Khởi động lại thiết bị',
  'facility.device.info.activityHistory.table.actionType.adjustVolume': 'Điều chỉnh âm lượng',
  'facility.device.info.activityHistory.table.actionType.news.letter.ended': 'Bản tin đã kết thúc',
  'facility.device.info.activityHistory.table.actionType.news.letter.resume': 'Phát tiếp bản tin',
  'facility.device.info.activityHistory.table.actionType.changeInfoSource': 'Đổi nguồn thông tin',
  'facility.media.title': 'Danh sách media cơ sở',
  'facility.media.card.creator': 'Người tạo',
  'facility.media.card.created': 'Ngày tạo',
  'facility.media.card.type': 'Loại media',
  'facility.media.card.processingStatus.param':
    '{status,select, 1 {Đang xử lý} 2 {Đã xử lý} other {Khác}}',
  'facility.media.card.status.param':
    '{status,select, 1 {Đã duyệt} 2 {Chờ duyệt} 3 {Từ chối duyệt} other {Khác}}',
  'facility.media.code.format.video': 'Video',
  'facility.media.code.format.image': 'Hình ảnh',
  'facility.media.code.format.stream': 'Phát trực tiếp',
  'facility.media.code.format.fmhz': 'Kênh FM',
  'facility.media.code.format.audio': 'Âm thanh',
  'facility.media.delete.title': 'Xác nhận xóa file media',
  'facility.media.delete.content':
    'Các media file được chọn sẽ bị xóa khỏi hệ thống và không thể khôi phục.',
  'facility.media.approve.title': 'Xác nhận duyệt media file',
  'facility.media.approve.content': 'Media file bạn chọn sẽ được phê duyệt',
  'facility.media.filter.approvalStatus': 'Trạng thái',
  'facility.media.filter.approvalStatus.approve': 'Đã duyệt',
  'facility.media.filter.approvalStatus.notApprovedYet': 'Chờ duyệt',
  'facility.media.filter.approvalStatus.refuse': 'Từ chối duyệt',
  'facility.media.filter.format.code': 'Loại media',
  'facility.media.filter.field.id': 'Lĩnh vực',
  'facility.media.filter.createdAt': 'Ngày tạo',
  'facility.media.modal.create': 'Thêm file media',
  'facility.media.modal.update': 'Cập nhật file media',
  'facility.media.modal.notEnough.capacity.title': 'Bạn đã hết dung lượng lưu trữ',
  'facility.media.modal.notEnough.capacity.content':
    'Hãy thay đổi gói cước khác hoặc giải phóng kho media file để tải media mới.',
  'facility.media.modal.form.name': 'Tên media file',
  'facility.media.modal.form.name.invalid': 'Tên media file không đúng định dạng',
  'facility.media.modal.form.fieldId': 'Lĩnh vực',
  'facility.media.modal.form.resourceType': 'Nội dung',
  'facility.media.modal.form.resourceType.0': 'Tệp tin',
  'facility.media.modal.form.resourceType.1': 'Tải từ đường dẫn',
  'facility.media.modal.form.resourceType.2': 'Phát trực tiếp',
  'facility.media.modal.form.resourceType.3': 'Kênh FM',
  'facility.media.modal.form.file': 'Tệp tin',
  'facility.media.modal.form.resourceUrl.url': 'Đường dẫn',
  'facility.media.modal.form.resourceUrl.stream': 'Liên kết phát trực tiếp',
  'facility.media.modal.form.resourceUrl.fm': 'Tần số kênh',
  'facility.media.modal.refuse.approval.title': 'Xác nhận từ chối duyệt media',
  'facility.media.modal.refuse.approval.content':
    'Bạn có chắc chắn muốn từ chối duyệt media này không?',
  'facility.media.modal.refuse.approval.reasonRefusal.label': 'Lý do từ chối',
  'facility.media.modal.refuse.approval.reasonRefusal.placeholder': 'Vui lòng nhập lý do từ chối',
  'facility.media.modal.shareMedia.title': 'Chia sẻ media',
  'facility.media.modal.shareMedia.tree.title': 'Chọn cơ sở để chia sẻ',
  'facility.media.modal.shareMedia.tree.required': 'Vui lòng chọn ít nhất 1 cơ sở để chia sẻ',
  'facility.media.modal.shareMedia.info.title': 'Thông tin file media',
  'facility.media.modal.shareMedia.info.name': 'Tên media file',
  'facility.media.modal.shareMedia.info.field': 'Lĩnh vực',
  'facility.media.modal.shareMedia.info.file.type': 'Loại file',
  'facility.media.modal.shareMedia.info.time': 'Thời lượng phát',
  'facility.media.modal.shareMedia.code.format.video': 'Video',
  'facility.media.modal.shareMedia.code.format.image': 'Hình ảnh',
  'facility.media.modal.shareMedia.code.format.stream': 'Phát trực tiếp',
  'facility.media.modal.shareMedia.code.format.fmhz': 'Kênh FM',
  'facility.media.modal.shareMedia.code.format.audio': 'Âm thanh',
  'facility.media.info.tabs.media': 'Thông tin media file',
  'facility.media.info.tabs.usageNews': 'Bản tin sử dụng',
  'facility.media.info.breadcrumbs.usageNews': 'Bản tin sử dụng',
  'facility.media.info.breadcrumbs.media.name': 'Thông tin media file',
  'facility.media.info.sidebar.title': 'Thông tin media file',
  'facility.media.info.sidebar.name': 'Tên media file',
  'facility.media.info.sidebar.field': 'Lĩnh vực',
  'facility.media.info.sidebar.code.format': 'Loại file',
  'facility.media.info.sidebar.resourceUrl.1': 'Nội dung',
  'facility.media.info.sidebar.resourceUrl.2': 'Nội dung',
  'facility.media.info.sidebar.duration': 'Thời lượng phát',
  'facility.media.info.sidebar.size': 'Dung lượng',
  'facility.media.info.sidebar.approvalStatus': 'Trạng thái',
  'facility.media.info.sidebar.approverName': 'Người phê duyệt',
  'facility.media.info.sidebar.creatorName': 'Người tạo',
  'facility.media.info.sidebar.createdAt': 'Ngày tạo',
  'facility.media.info.sidebar.description': 'Mô tả',
  'facility.media.info.sidebar.reasonRefusal': 'Lý do từ chối',
  'facility.media.info.sidebar.facilityShare': 'Cơ sở chia sẻ',
  'facility.media.info.sidebar.code.format.video': 'Video',
  'facility.media.info.sidebar.code.format.image': 'Hình ảnh',
  'facility.media.info.sidebar.code.format.stream': 'Phát trực tiếp',
  'facility.media.info.sidebar.code.format.fmhz': 'Kênh FM',
  'facility.media.info.sidebar.code.format.audio': 'Âm thanh',
  'facility.media.info.sidebar.badge.processingStatus.param':
    '{status,select, 1 {Đang xử lý} 2 {Đã xử lý} other {Khác}}',
  'facility.media.info.sidebar.badge.status.param':
    '{status,select, 1 {Đã duyệt} 2 {Chờ duyệt} 3 {Từ chối duyệt} other {Khác}}',
  'facility.media.info.title': 'Thông tin media file',
  'facility.media.info.table.facility.name': 'Cơ sở chia sẻ',
  'facility.media.info.table.sharingTime': 'Thời gian chia sẻ',
  'facility.media.info.table.receptionStatus': 'Trạng thái tiếp nhận',
  'facility.media.info.table.receptionStatus.param':
    '{status,select, 1 {Chưa tiếp nhận} 2 {Đã tiếp nhận} other {Khác}}',
  'facility.media.info.delete.title': 'Xác nhận xóa file media',
  'facility.media.info.delete.content':
    'Các media file được chọn sẽ bị xóa khỏi hệ thống và không thể khôi phục.',
  'facility.media.info.approve.title': 'Xác nhận duyệt media file',
  'facility.media.info.approve.content': 'Media file bạn chọn sẽ được phê duyệt',
  'facility.media.info.filter.sharingTime': 'Thời gian chia sẻ',
  'facility.media.info.filter.receptionStatus': 'Trạng thái',
  'facility.media.info.filter.receptionStatus.notUsedYet': 'Chưa tiếp nhận',
  'facility.media.info.filter.receptionStatus.used': 'Đã tiếp nhận',
  'facility.template.router.name': 'Cơ sở',
  'facility.template.title': 'Danh sách bản mẫu cơ sở',
  'facility.template.table.name': 'Tên bản mẫu',
  'facility.template.table.resolutionWidth': 'Tỉ lệ màn hình',
  'facility.template.table.createdAt': 'Ngày tạo',
  'facility.template.table.creator.name': 'Người tạo',
  'facility.template.table.usageStatus': 'Trạng thái sử dụng',
  'facility.template.table.userStatus.param':
    '{status,select,  1 {Chưa sử dụng} 2 {Đang sử dụng} other {Không xác định}}',
  'facility.template.delete.title': 'Xác nhận xóa bản mẫu',
  'facility.template.delete.content':
    'Các bản mẫu được chọn sẽ bị xóa khỏi hệ thống và không thể khôi phục',
  'facility.template.filter.createdAt': 'Ngày tạo',
  'facility.template.create.code.format.video': 'Video',
  'facility.template.create.code.format.image': 'Hình ảnh',
  'facility.template.create.code.format.stream': 'Phát trực tiếp',
  'facility.template.create.code.format.fmhz': 'Kênh FM',
  'facility.template.create.code.format.audio': 'Âm thanh',
  'facility.template.router.create.name': 'Tạo bản mẫu',
  'facility.template.router.create.edit': 'Cập nhật bản mẫu',
  'facility.template.create.audio': 'Âm thanh sử dụng',
  'facility.template.create.form.templateName': 'Tên bản mẫu',
  'facility.template.create.form.templateName.placeholder': 'Tên bản mẫu',
  'facility.template.create.form.ratio': 'Tỷ lệ màn hình',
  'facility.template.create.form.ratioWidth.required': 'Vui lòng nhập chiều ngang',
  'facility.template.create.form.ratioWidth': 'Chiều ngang',
  'facility.template.create.form.ratioWidth.placeholder': 'Chiều ngang',
  'facility.template.create.form.ratioHeight': 'Chiều dọc',
  'facility.template.create.form.ratioHeight.placeholder': 'Chiều dọc',
  'facility.template.create.form.ratioHeight.required': 'Vui lòng nhập chiều dọc',
  'facility.template.create.form.duration': 'Thời lượng',
  'facility.template.create.form.duration.placeholder': 'Thời lượng',
  'facility.template.create.form.duration.required': 'Vui lòng nhập Thời lượng',
  'facility.template.create.form.media': 'Nguồn thông tin',
  'facility.template.create.form.media.placeholder': 'Tìm kiếm ...',
  'facility.template.create.form.size': 'Kích thước (%)',
  'facility.template.create.form.width': 'Ngang',
  'facility.template.create.form.height': 'Dọc',
  'facility.template.create.form.position': 'Vị trí (%)',
  'facility.template.create.form.positionX': 'Trục X',
  'facility.template.create.form.positionY': 'Trục Y',
  'facility.template.create.form.zIndex': 'Z-index',
  'facility.template.info.name.breadcrumbs': 'Thông tin bản mẫu',
  'facility.template.newsLetters.name.breadcrumbs': 'Bản tin sử dụng',
  'facility.template.info.tabs': 'Thông tin bản mẫu',
  'facility.template.newsLetters.tabs': 'Bản tin sử dụng',
  'facility.router.playlist.create.name': 'Thêm danh sách phát cơ sở',
  'facility.router.playlist.update.name': 'Cập nhật danh sách phát cơ sở',
  'facility.router.playlist.info.name': 'Thông tin danh sách phát cơ sở',
  'facility.router.playlist.name': 'Danh sách phát cơ sở',
  'facility.router.playlist.organization.create.name': 'Thêm danh sách phát',
  'facility.router.playlist.organization.update.name': 'Thêm danh sách phát',
  'facility.playlist.title': 'Danh sách phát cơ sở',
  'facility.playlist.table.name': 'Tên danh sách phát',
  'facility.playlist.table.duration': 'Thời lượng phát',
  'facility.playlist.table.field.name': 'Lĩnh vực',
  'facility.playlist.table.creator.name': 'Người tạo',
  'facility.playlist.table.createdAt': 'Ngày tạo',
  'facility.playlist.table.usageStatus': 'Trạng thái sử dụng',
  'facility.playlist.table.usageStatus.param':
    '{status,select, 1 {Chưa sử dụng} 2 {Đang sử dụng}  other {Undefined}}',
  'facility.playlist.delete.title': 'Xác nhận xóa danh sách phát',
  'facility.playlist.delete.content':
    'Các danh sách phát được chọn sẽ bị xóa khỏi hệ thống và không thể khôi phục.',
  'facility.playlist.filter.fields': 'Lĩnh vực',
  'facility.playlist.filter.createdAt': 'Ngày tạo',
  'facility.playlist.update.title': 'Cập nhật danh sách phát',
  'facility.playlist.create.title': 'Thêm danh sách phát',
  'facility.playlist.create.mainCard.title': 'Danh sách các nguồn thông tin',
  'facility.playlist.create.table.order': 'Thứ tự phát',
  'facility.playlist.create.table.name': 'Tên nguồn phát',
  'facility.playlist.create.table.duration': 'Thời lượng ',
  'facility.playlist.create.table.sourceType': 'Nguồn thông tin',
  'facility.playlist.create.table.sourceType.1': 'Bản mẫu',
  'facility.playlist.create.table.sourceType.2': 'Media',
  'facility.playlist.create.code.format.video': 'Video',
  'facility.playlist.create.code.format.image': 'Hình ảnh',
  'facility.playlist.create.code.format.stream': 'Phát trực tiếp',
  'facility.playlist.create.code.format.fmhz': 'Kênh FM',
  'facility.playlist.create.code.format.audio': 'Âm thanh',
  'facility.playlist.create.form.emptySource.warning': 'Danh sách các nguồn thông tin trống',
  'facility.playlist.create.form.name.warning': 'Vui lòng nhập tên danh sách phát',
  'facility.playlist.create.form.name.warning255': 'Chiều dài quá 255 ký tự',
  'facility.playlist.create.form.name': 'Tên',
  'facility.playlist.create.form.name.placeholder': 'Tên',
  'facility.playlist.create.form.field': 'Lĩnh vực',
  'facility.playlist.create.form.field.placeholder': 'Lĩnh vực',
  'facility.playlist.create.form.playMode': 'Chế độ phát',
  'facility.playlist.create.form.playMode-1': 'Có thứ tự',
  'facility.playlist.create.form.playMode-2': 'Ngẫu nhiên',
  'facility.playlist.create.form.duration': 'Thời lượng danh sách phát',
  'facility.playlist.create.form.duration.placeholder': 'Thời lượng danh sách phát',
  'facility.playlist.create.form.description': 'Mô tả',
  'facility.playlist.create.form.description.placeholder': 'Mô tả',
  'facility.playlist.create.modal.sourceSelected.title': 'Nguồn thông tin đã chọn',
  'facility.playlist.create.modal.template.table.name': 'Tên nguồn phát',
  'facility.playlist.create.modal.template.table.duration': 'Thời lượng',
  'facility.playlist.create.modal.template.table.source-count': 'Số lần thêm',
  'facility.playlist.create.modal.templateDisplay.table.name': 'Tên',
  'facility.playlist.create.modal.templateDisplay.table.duration': 'Thời lượng',
  'facility.playlist.create.modal.templateDisplay.table.button.remove': 'Gỡ',
  'facility.playlist.create.modal.form.duration.warning':
    'Thời lượng của tất cả nguồn thông tin phải lớn hơn 0',
  'facility.playlist.create.modal.filter.field': 'Lĩnh vực',
  'facility.playlist.create.modal.title': 'Thêm nguồn thông tin',
  'facility.playlist.create.modal.form.sourceType': 'Nguồn thông tin',
  'facility.playlist.create.modal.form.sourceType.1': 'Bản mẫu',
  'facility.playlist.create.modal.form.sourceType.2': 'Media',
  'facility.playlist.create.modal.source.options.title':
    'Chọn các nguồn thông tin cần thêm vào danh sách phát',
  'facility.playlist.info.tabs.info': 'Thông tin danh sách phát',
  'facility.playlist.info.tabs.news': 'Bản tin sử dụng',
  'facility.playlist.info.breadcrumbs.news': 'Bản tin sử dụng',
  'facility.playlist.info.breadcrumbs.info': 'Thông tin danh sách phát',
  'facility.playlist.info.title': 'Danh sách các nguồn thông tin',
  'facility.playlist.info.table.order': 'Thứ tự phát',
  'facility.playlist.info.table.sourceName': 'Tên nguồn phát',
  'facility.playlist.info.table.duration': 'Thời lượng ',
  'facility.playlist.info.table.sourceType': 'Nguồn thông tin',
  'facility.playlist.info.table.sourceType.1': 'Bản mẫu',
  'facility.playlist.info.table.sourceType.2': 'Media',
  'facility.playlist.info.delete.title': 'Xác nhận xóa danh sách phát',
  'facility.playlist.info.delete.content':
    'Các danh sách phát được chọn sẽ bị xóa khỏi hệ thống và không thể khôi phục.',
  'facility.playlist.info.sidebar.title': 'Thông tin danh sách phát',
  'facility.playlist.info.sidebar.name': 'Tên danh sách phát',
  'facility.playlist.info.sidebar.field': 'Lĩnh vực',
  'facility.playlist.info.sidebar.creator': 'Người tạo',
  'facility.playlist.info.sidebar.createdAt': 'Ngày tạo',
  'facility.playlist.info.sidebar.duration': 'Thời lượng phát',
  'facility.playlist.info.sidebar.playMode': 'Chế độ phát',
  'facility.playlist.info.sidebar.description': 'Mô tả',
  'facility.playlist.info.sidebar.playMode.1': 'Có thứ tự',
  'facility.playlist.info.sidebar.playMode.2': 'Ngẫu nhiên',
  'facility.device.quantity.label': 'Số lượng thiết bị',
  'facility.device.quantity.warning.title': 'Bạn đã đạt mức giới hạn thiết bị',
  'facility.device.quantity.warning.content':
    'Số lượng thiết bị đã đạt giới hạn của gói cước đang sử dụng, bạn có thể thay đổi gói cước khác hoặc xóa những thiết bị không hoạt động.',
  'facility.device.quantity.warning.btn.upgrade': 'Nâng cấp',
  'facility.device.quantity.warning.message':
    'Bạn không có quyền truy cập chức năng này, hãy liên hệ với chủ tổ chức.',
  'facility.device.inform.title': 'Thông báo',
  'facility.device.inform.content':
    'Bạn đang tiếp cận giới hạn thiết bị của kế hoạch sắp tới. Theo kế hoạch đến ngày {date}, gói cước mới sẽ giới hạn ở {deviceQuantity} thiết bị. Bạn có thể cân nhắc điều chỉnh gói cước phù hợp hơn.',
  'facility.device.inform.okText': 'Quản lý gói cước',

  // update device group
  'facility.device.table.deviceGroup': 'Nhóm thiết bị',
  'facility.modal.create.form.deviceGroup': 'Nhóm thiết bị',
  'facility.device.info.detail.sidebar.deviceGroup': 'Nhóm thiết bị',
  'facility.device.filter.deviceGroups': 'Nhóm thiết bị',
};
