import React from 'react';
import { Layers } from 'react-feather';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerFields: IRouter = {
  path: '/settings/fields',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'settings.endUser.router.fields.name', //translate here for breadcrumb and sidebar
  nameBreadcrumb: 'settings.endUser.router.fields.nameBreadcrumb',
  masterLayout: true,
  icon: <Layers />,
  permissionCode: PermissionEnum.FieldsView,
};

export const routerOrganizationFields: IRouter = {
  path: '/settings/organization/:organizationId/fields',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'settings.endUser.router.fields.name', //translate here for breadcrumb and sidebar
  nameBreadcrumb: 'settings.endUser.router.fields.nameBreadcrumb444',
  masterLayout: true,
  icon: <Layers />,
  activePath: '/fields',
  permissionCode: PermissionEnum.FieldsView,
  generatePath(organizationId) {
    return `/settings/organization/${organizationId}/fields`;
  },
};
