import React from 'react';

import { UsergroupAddOutlined } from '@ant-design/icons';
import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerSuppliers: IRouter = {
  path: '/settings/suppliers',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'settings.endUser.suppliers.router.name', //translate here for breadcrumb and sidebar
  nameBreadcrumb: 'settings.endUser.suppliers.router.nameBreadcrumb22',
  masterLayout: true,
  icon: <UsergroupAddOutlined />,
  permissionCode: PermissionEnum.SuppliersView,
};

export const routerOrganizationSuppliers: IRouter = {
  path: 'settings/organization/:organizationId/suppliers',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'settings.endUser.suppliers.router.name', //translate here for breadcrumb and sidebar
  nameBreadcrumb: 'settings.endUser.suppliers.router.nameBreadcrumb',
  masterLayout: true,
  icon: <UsergroupAddOutlined />,
  activePath: '/suppliers',
  permissionCode: PermissionEnum.SuppliersView,
  generatePath(organizationId) {
    return `/settings/organization/${organizationId}/suppliers`;
  },
};
